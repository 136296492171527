import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    if (this.input?.setSelectionRange) {
      this.input.focus()

      if (this.input?.getAttribute("type") === "time")
        return

      const len = this.input.value.length * 2
      this.input.setSelectionRange(len, len)
    }
  }

  get input() {
    return this.element.querySelector("[autofocus]")
  }
}
