import { Controller } from "@hotwired/stimulus"
import Reactor from "../../lib/reactor"

export default class extends Controller {
  static values = {
    from: String,
    until: String,
    fromMinute: Number,
    untilMinute: Number,
    timeZone: String,
    minimum: Number,
    maximum: Number,
    monday: Boolean,
    tuesday: Boolean,
    wednesday: Boolean,
    thursday: Boolean,
    friday: Boolean,
    saturday: Boolean,
    sunday: Boolean,
  }

  connect() {
    new Reactor(this)
    this.restoreTimeValue("from")
    this.restoreTimeValue("until")
  }

  fromValueChanged(value) {
    this.updateTimeValue("from", value)
  }

  untilValueChanged(value) {
    this.updateTimeValue("until", value)
  }

  restoreTimeValue(key) {
    const hour   = ("" + Math.floor(this[`${key}MinuteValue`] / 60)).padStart(2, "0")
    const minute = ("" + (this[`${key}MinuteValue`] % 60)).padStart(2, "0")

    this[`${key}Value`] = `${hour}:${minute}`
  }

  updateTimeValue(key, value) {
    const [hour, minute] = value.split(":")
    this[`${key}MinuteValue`] = (parseInt(hour) * 60) + parseInt(minute)
  }

  description() {
    return `Overrides the minimum to ${this.minimumValue} and maximum to ${this.maximumValue}
            between ${this.fromValue} and ${this.untilValue} (${this.timeZoneValue} Time)
            ${this.daysDescription}`
  }

  get daysDescription() {
    const buffer = []

    if (this.mondayValue) buffer.push("Monday")
    if (this.tuesdayValue) buffer.push("Tuesday")
    if (this.wednesdayValue) buffer.push("Wednesday")
    if (this.thursdayValue) buffer.push("Thursday")
    if (this.fridayValue) buffer.push("Friday")
    if (this.saturdayValue) buffer.push("Saturday")
    if (this.sundayValue) buffer.push("Sunday")

    if (buffer.length)
      return `<br />on ${buffer.join(", ")}`
    else
      return ``
  }
}
