import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.element.addEventListener("click", event => {
      event.preventDefault()
      this.modal?.dispatchEvent(this.openModalEvent)
    })
  }

  get openModalEvent() {
    return new CustomEvent("modal:open", {
      detail: {
        title: this.element.title,
        url: this.element.href,
      }
    })
  }

  get modal() {
    return document.getElementById("modal-component")
  }
}
