import { Controller } from "@hotwired/stimulus"

// Dispatches the "close" event to the modal node when the server returns a successful response.
// The modal listens for this event, and upon reception it will close itself.
//
export default class extends Controller {
  initialize() {
    this.onSubmitEnd = this.onSubmitEnd.bind(this)
    this.closeModal = this.closeModal.bind(this)
  }

  connect() {
    this.form?.addEventListener("turbo:submit-end", this.onSubmitEnd)
  }

  onSubmitEnd(event) {
    if (event?.detail?.success === true)
      setTimeout(this.closeModal, 150)
  }

  closeModal() {
    this.modal?.dispatchEvent(new CustomEvent("modal:close"))
  }

  get form() {
    if (this.element.tagName === "FORM")
      return this.element
    else
      return this.element.closest("form")
  }

  get modal() {
    return document.getElementById("modal-component")
  }
}
