import { Controller } from "@hotwired/stimulus"

// Hides elements that have the data-show-target="hidden" attribute.
// Shows these elements when calling show().
export default class extends Controller {
  static targets = ["hidden"]

  connect() {
    this.originalDisplay = this.hiddenTarget.style.display
    this.hiddenTargets.forEach(e => e.style.display = "none")
  }

  show(event) {
    event.preventDefault()
    event.target.style.display = "none"
    this.hiddenTargets.forEach(e => e.style.display = this.originalDisplay)
  }
}
