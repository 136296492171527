import { Controller } from "@hotwired/stimulus"

// Removes an element after the specified delay
//
export default class extends Controller {
  static values = {delay: Number}

  connect() {
    setTimeout(() => this.hide(), this.delayValue)
  }

  hide() {
    this.element.remove()
  }
}
