import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.element.addEventListener("change", this.submitForm)
  }

  disconnect() {
    this.element.removeEventListener("change", this.submitForm)
  }

  submitForm = () => {
    this.element.closest("form").requestSubmit()
  }
}
