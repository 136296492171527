import { Controller } from "@hotwired/stimulus"
import Reactor from "../../lib/reactor"

export default class extends Controller {
  static targets = [
    "turboframe",
  ]

  static values = {
    id: String,
    applicationId: String,
    showInstructions: Boolean,
    showWebFrameworkOptions: Boolean,
    showWorkerLibraryOptions: Boolean,
  }

  connect() {
    this.frameLoadedListener = document.addEventListener(
      "turbo:frame-load",
      this.instructionsFrameLoadedHandler.bind(this)
    )

    new Reactor(this)
  }

  disconnect() {
    document.removeEventListener("turbo:frame-load", this.frameLoadedListener)
  }

  show(event) {
    event.preventDefault()
    this.showInstructionsValue = true
    this.renderSpinner()
    const path = `/applications/${this.applicationIdValue}/instructions/${this.idValue}`
    const url = new URL(path, window.location.origin)
    this.turboframeTarget.src = url.toString()
  }

  showInstructionsButton() {
    return !this.showInstructionsValue
  }

  toggleWebFrameworkOptions(event) {
    event.preventDefault()
    this.showWebFrameworkOptionsValue = !this.showWebFrameworkOptionsValue
  }

  showWebFrameworkOptions() {
    return this.showWebFrameworkOptionsValue
  }

  toggleWorkerLibraryOptions(event) {
    event.preventDefault()
    this.showWorkerLibraryOptionsValue = !this.showWorkerLibraryOptionsValue
  }

  showWorkerLibraryOptions() {
    return this.showWorkerLibraryOptionsValue
  }

  linkClicked() {
    this.showWebFrameworkOptionsValue = false
    this.showWorkerLibraryOptionsValue = false
  }

  renderSpinner() {
    this.turboframeTarget.innerHTML = `
      <svg data-controller="spinner" class="text-white w-16 h-16 m-auto p-4" />
    `
  }

  instructionsFrameLoadedHandler(event) {
    if (event.target.id === "instructions") {
      this.findTextAndReplaceWithNode(this.element, "__MANAGER__NAME__", () => {
        const node = document.createElement("span")
        node.setAttribute("data-manager--form-text", "name")
        return node
      })
    }
  }

  findTextAndReplaceWithNode(node, text, buildNode) {
    node.childNodes.forEach(childNode => {
      if (childNode.nodeType === Node.TEXT_NODE && childNode.textContent.includes(text)) {
        const fullText = childNode.textContent
        const parts = fullText.split(text)
        const fragment = document.createDocumentFragment()

        parts.forEach((part, index) => {
          fragment.appendChild(document.createTextNode(part))
          if (index < parts.length - 1) {
            fragment.appendChild(buildNode())
          }
        })

        childNode.parentNode.replaceChild(fragment, childNode)
      } else if (childNode.nodeType === Node.ELEMENT_NODE) {
        this.findTextAndReplaceWithNode(childNode, text, buildNode)
      }
    })
  }
}
