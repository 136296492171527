// Turbo Drive Render Hack
//
// Overrides the PageRenderer.assignNewBody function to add the ability to change
// rendering targets.
//
// Caching must be disabled in order for this to work:
//
//    <meta content="no-cache" name="turbo-cache-control">
//
//
// Change rendering targets (from the default <body> tag) to any element containing
// the data-turbo-drive-body attribute:
//
//   <body>
//     <div data-turbo-drive-body> <!-- render turbo content here --> </div>
//     <!-- instead of here -->
//   </body>
//
// Defaults to calling the original PageRenderer.assignNewBody function in the event
// that the data-turbo-drive-body cannot be found.
//
// Additional information/discussion around this ability:
//
//   https://github.com/hotwired/turbo/pull/305
//
// The following might allow for this use of this approach with caching enabled:
//
//   https://github.com/hotwired/turbo/pull/305#issuecomment-884945064

import { Turbo } from "@hotwired/turbo-rails"

const originalAssignNewBody = Turbo.PageRenderer.prototype.assignNewBody

Turbo.PageRenderer.prototype.assignNewBody = function() {
  const currentTurboDriveBody = document.querySelector("[data-turbo-drive-body]")
  const newTurboDriveBody = this.newElement.querySelector("[data-turbo-drive-body]")

  if (currentTurboDriveBody && newTurboDriveBody)
    currentTurboDriveBody.replaceWith(newTurboDriveBody)
  else
    originalAssignNewBody.apply(this)
}
