import { Controller } from "@hotwired/stimulus"
import QRious from "qrious"

// @example
//   <canvas data-controller="qrcode" data-qrcode-uri-value="<%= otp_provisioning_uri %>"></canvas>
export default class extends Controller {
  static values = { uri: String }

  connect() {
    new QRious({element: this.element, value: this.uriValue, size: 175})
  }
}
