import { Controller } from "@hotwired/stimulus"
import { v4 as uuidv4 } from "uuid"

// Creates a hidden field with the name form[id] containing a newly generated uuid v4 token.
//
// Useful for selecting forms using turbo streams.
//
// Example:
//
//   turbo_stream.replace(params[:form][:id]) do
//     render "form", input: @input
//   end
//
export default class extends Controller {
  connect() {
    const id = uuidv4()
    const input = document.createElement("input")
    input.type = "hidden"
    input.name = "form[id]"
    input.value = id
    this.element.id = id
    this.element.prepend(input)
  }
}
