import { Controller } from "@hotwired/stimulus"
import { setClass, sleep } from "../lib/helpers"

export default class extends Controller {
  static targets = ["thumb"]
  static values = {
    state: Array,
    baseClass: String,
    pendingDisabledClass: String,
    pendingEnabledClass: String,
    pendingIssueClass: String,
    disabledClass: String,
    enabledClass: String,
    issueClass: String
  }

  connect() {
    this.form.addEventListener("turbo:submit-start", (event) => this.onSubmit(event))
    this.form.addEventListener("turbo:submit-end", (event) => this.onSubmitEnd(event))
  }

  onSubmit(event) {
    this.stateValue = ["pending", ...this.stateValue]
  }

  onSubmitEnd(event) {
    if (event?.detail?.success === false)
      this.stateValue = [this.stateValue[1]]
  }

  stateValueChanged() {
    setClass(this.thumbTarget, this.stateClasses)
  }

  hasState(...states) {
    for (const index in states)
      if (!this.stateValue.includes(states[index])) return false

    return true
  }

  get stateClasses() {
    if (this.hasState("pending", "disabled"))
      return [this.baseClassValue, this.pendingDisabledClassValue]
    if (this.hasState("pending", "enabled"))
      return [this.baseClassValue, this.pendingEnabledClassValue]
    if (this.hasState("pending", "issue"))
      return [this.baseClassValue, this.pendingIssueClassValue]
    if (this.hasState("disabled"))
      return [this.baseClassValue, this.disabledClassValue]
    if (this.hasState("enabled"))
      return [this.baseClassValue, this.enabledClassValue]
    if (this.hasState("issue"))
      return [this.baseClassValue, this.issueClassValue]

    return []
  }

  get form() {
    return this.element.closest("form")
  }
}
