// TODO: implement -hide and -unless
//
// Reactor (for Stimulus.js)
// =========================
//
// Introduces the following bindings to Stimulus controllers:
//
//  - data-<controller_name>-model
//  - data-<controller_name>-text
//  - data-<controller_name>-html
//  - data-<controller_name>-class
//  - data-<controller_name>-show
//  - data-<controller_name>-if
//
// These bindings remove the boilerplate code that is typically
// present in Stimulus controllers when dealing with:
//
//  - Reading and storing input values
//  - Rendering values and dynamic content (as text or html)
//  - Conditionally adding/removing classes to/from elements
//  - Conditionally showing/hiding elements
//  - Conditionally inserting/removing elements
//
// Reactor's API is designed to follow the Stimulus API- and naming conventions,
// and integrate seamlessly with Stimulus (static) values and callbacks.
//
// Reactor, like Stimulus, uses MutationObserver to listen for changes within the
// scope of each controller in order to react to both internal and external operations,
// and to update the DOM based on the controller's state.
//
//
// Usage
// =====
//
// Import the Reactor class, instantiate it inside of the controller's connect()
// callback and pass the controller to it. The bindings are now in effect.
//
//   import { Controller } from "stimulus"
//   import Reactor from "reactor"
//
//   export default class extends Controller {
//     connect() {
//       new Reactor(this)
//     }
//   }
//
//
// Examples
// ========
//
// Note that all of the JavaScript functions in the following examples
// are defined in the context of the following Stimulus Controller.
//
//   import { Controller } from "stimulus"
//   import Reactor from "reactor"
//
//   // example_controller.js
//   export default class extends Controller {
//     static values = { name: String }
//
//     connect() {
//       new Reactor(this)
//     }
//
//     namePresent() {
//       return this.nameValue.length > 0
//     }
//
//     nameDescription() {
//       return `Your name is ${this.nameValue}`
//     }
//
//     nameClasses() {
//       if (this.namePresent())
//         return "name-present"
//       else
//         return ["name-missing", "name-error"]
//     }
//   }
//
//
// Example: Bind an input value to the controller's nameValue
//
//   <input data-example-model="name" />
//
//
// Example: Bind the controller's nameValue to an element
//
//   <span data-example-text="name"></span>
//
//
// Example: Bind nameDescription as a text node to an element
//
//   <span data-example-text="nameDescriptionAsText"></span>
//
//
// Example: Bind nameDescription as HTML to an element. Note that the
//          rendered HTML is sanitized using DOMPurify prior to being rendered
//
//   <span data-example-html="nameDescriptionAsHTML"></span>
//
//
// Example: Conditionally show/hide an element.
//
//   <span data-example=show="namePresent">Your name is present</span>
//
//
// Example: Conditionally insert/remove a template fragment.
//
//   <template data-example-if="namePresent">
//     <div>Hello, <span data-example-text="name"></span></div>
//   </template>
//
//
// Example: Conditionally add additional classes to an element.
//
//   <div class="name-base" data-example-class="nameClasses"></div>
//
//
// Dependencies
// ============
//
//   - DOMPurify
//     Security measure against malicious activity (i.e. XSS attacks)
//     when using data-<controller_name>-html.
//
//
// Considerations
// ==============
//
//   - Content Security Policy (CSP) friendly.
//     No eval() or Function() shenanigans.

import DOMPurify from "dompurify"

export default class Reactor {
  constructor(controller) {
    this.controller             = controller
    this.setControllerValues    = {}
    this.modelAttribute         = `data-${controller.scope.identifier}-model`
    this.modelMatcher           = `[${this.modelAttribute}]`
    this.textAttribute          = `data-${controller.scope.identifier}-text`
    this.textMatcher            = `[${this.textAttribute}]`
    this.htmlAttribute          = `data-${controller.scope.identifier}-html`
    this.htmlMatcher            = `[${this.htmlAttribute}]`
    this.showAttribute          = `data-${controller.scope.identifier}-show`
    this.showMatcher            = `[${this.showAttribute}]`
    this.ifAttribute            = `data-${controller.scope.identifier}-if`
    this.ifMatcher              = `[${this.ifAttribute}]`
    this.ifSrcAttribute         = `data-${controller.scope.identifier}-if-src`
    this.ifDstAttribute         = `data-${controller.scope.identifier}-if-dst`
    this.classAttribute         = `data-${controller.scope.identifier}-class`
    this.classMatcher           = `[${this.classAttribute}]`
    this.staticClassAttribute   = `data-${controller.scope.identifier}-static-class`
    this.modelInputEventHandler = this.modelInputEventHandler.bind(this)

    this.update()
    this.observe()
  }

  observe() {
    const attributes = {
      [this.modelAttribute]: true,
      [this.textAttribute]: true,
      [this.htmlAttribute]: true,
      [this.classAttribute]: true,
      [this.showAttribute]: true,
      [this.ifAttribute]: true,
    }

    const isElementAttributeRemoved = (mutation, attribute) => {
      return (mutation.attributeName === attribute && !mutation.target.hasAttribute(attribute))
    }

    const observer = new MutationObserver(mutations => {
      for (const mutation of mutations) {
        if (mutation.target === this.controller.element) {
          this.update()
          break
        }

        if (attributes[mutation.attributeName]) {
          if (isElementAttributeRemoved(mutation, this.textAttribute))
            this.setElementText(mutation.target)

          if (isElementAttributeRemoved(mutation, this.htmlAttribute))
            this.setElementHTML(mutation.target)

          if (isElementAttributeRemoved(mutation, this.showAttribute))
            this.setElementDisplay(mutation.target)

          if (isElementAttributeRemoved(mutation, this.classAttribute))
            this.setElementClass(mutation.target)

          this.update()
          break
        }

        for (const node of mutation.addedNodes) {
          if (node.nodeType === Node.ELEMENT_NODE) {
            this.update()
            break
          }
        }
      }
    })

    observer.observe(
      this.controller.element,
      {
        childList: true,
        subtree: true,
        attributes: true
      }
    )
  }

  update() {
    this.warnMissingValueDefinitions()
    this.updateControllerValuesWithElementValues()
    this.updateModelElements()
    this.updateTextElements()
    this.updateHTMLElements()
    this.updateClassElements()
    this.updateShowElements()
    this.updateIfElements()
    this.updateModelElementsEventListeners()
  }

  warnMissingValueDefinitions() {
    this.controller.element
      .querySelectorAll(this.modelMatcher)
      .forEach(this.warnMissingValueDefinition.bind(this))
  }

  warnMissingValueDefinition(element) {
    const property = element.getAttribute(this.modelAttribute)

    if (this.controller.constructor.values[property] === undefined)
      console.warn(`[Reactor] Missing value definition: ${property} (${property}Value). ` +
                   `Define it on the ${this.controller.identifier} Stimulus controller. ` +
                   `For example: static values = { ${property}: String }`)
  }

  updateControllerValuesWithElementValues() {
    if (this.controller.element.hasAttribute(this.modelAttribute))
      this.updateControllerValueWithElementValue(this.controller.element)

    this.controller.element
      .querySelectorAll(this.modelMatcher)
      .forEach(this.updateControllerValueWithElementValue.bind(this))
  }

  updateControllerValueWithElementValue(element) {
    const property = element.getAttribute(this.modelAttribute)
    const value    = this.getElementValue(element)

    if (this.isControllerValueUnset(property)) {
      this.setControllerValue(property, value)
      this.runChangedCallback(property, value)
    }
  }

  runChangedCallback(property, value) {
    if (this.controller.constructor.values[property] !== undefined)
      return

    const fn = this.controller[`${property}ValueChanged`]

    if (typeof fn === "function")
      fn.call(this.controller, value)
  }

  updateModelElementsEventListeners() {
    if (this.controller.element.hasAttribute(this.modelAttribute))
      this.updateModelElementEventListener(this.controller.element)

    this.controller.element
      .querySelectorAll(this.modelMatcher)
      .forEach(this.updateModelElementEventListener.bind(this))
  }

  updateModelElementEventListener(element) {
    element.removeEventListener("input", this.modelInputEventHandler)

    if (element.hasAttribute(this.modelAttribute))
      element.addEventListener("input", this.modelInputEventHandler)
  }

  modelInputEventHandler (event) {
    const property = event.target.getAttribute(this.modelAttribute)
    const value    = this.getElementValue(event.target)

    this.setControllerValue(property, value)
    this.runChangedCallback(property, value)
    this.update()
  }

  updateModelElements() {
    if (this.controller.element.hasAttribute(this.modelAttribute))
      this.updateModelElement(this.controller.element)

    this.controller.element
      .querySelectorAll(this.modelMatcher)
      .forEach(this.updateModelElement.bind(this))
  }

  updateModelElement(element) {
    const property = element.getAttribute(this.modelAttribute)
    const value    = this.getControllerValue(property)

    if (value === undefined || this.isNaNType(value))
      this.setElementValue(element)
    else
      this.setElementValue(element, value)
  }

  setElementValue(element, value = "") {
    if (element.value !== value)
      element.value = value
  }

  updateTextElements() {
    if (this.controller.element.hasAttribute(this.textAttribute))
      this.updateTextElement(this.controller.element)

    this.controller.element
      .querySelectorAll(this.textMatcher)
      .forEach(this.updateTextElement.bind(this))
  }

  updateTextElement(element) {
    const property = element.getAttribute(this.textAttribute)
    const value    = this.getControllerValue(property)

    if (this.isRenderable(value))
      this.setElementText(element, value)
    else
      this.setElementText(element)
  }

  setElementText(element, value = "") {
    if (element.innerText !== value)
      element.innerText = value
  }

  updateHTMLElements()  {
    if (this.controller.element.hasAttribute(this.htmlAttribute))
      this.updateHTMLElement(this.controller.element)

    this.controller.element
      .querySelectorAll(this.htmlMatcher)
      .forEach(this.updateHTMLElement.bind(this))
  }

  updateHTMLElement(element) {
    const property = element.getAttribute(this.htmlAttribute)
    const value    = this.getControllerValue(property)

    if (typeof value === "string")
      this.setElementHTML(element, value)
    else
      this.setElementHTML(element)
  }

  setElementHTML(element, value = "") {
    const html = DOMPurify.sanitize(value, {USE_PROFILES: {html: true}})

    if (element.innerHTML !== html)
      element.innerHTML = html
  }

  updateClassElements() {
    if (this.controller.element.hasAttribute(this.classAttribute))
      this.updateClassElement(this.controller.element)

    this.controller.element
      .querySelectorAll(this.classMatcher)
      .forEach(this.updateClassElement.bind(this))
  }

  updateClassElement(element) {
    const property = element.getAttribute(this.classAttribute)
    const value    = this.getControllerValue(property)

    if (!element.hasAttribute(this.staticClassAttribute))
      element.setAttribute(this.staticClassAttribute, element.getAttribute("class") || "")

    if (Array.isArray(value))
      this.setElementClass(element, value)
    else if (typeof value === "string")
      this.setElementClass(element, [value])
    else
      this.setElementClass(element)
  }

  setElementClass(element, list = []) {
    element.className = element.getAttribute(this.staticClassAttribute)
    list.forEach(className => element.classList.add(className))
  }

  updateShowElements() {
    if (this.controller.element.hasAttribute(this.showAttribute))
      this.updateShowElement(this.controller.element)

    this.controller.element
      .querySelectorAll(this.showMatcher)
      .forEach(this.updateShowElement.bind(this))
  }

  updateShowElement(element) {
    const property = element.getAttribute(this.showAttribute)
    const value    = this.getControllerValue(property)

    if (property === null || this.isVisible(value))
      this.setElementDisplay(element)
    else
      this.setElementDisplay(element, "none")
  }

  setElementDisplay(element, value = "") {
    if (element.style.display !== value)
      element.style.display = value
  }

  updateIfElements() {
    if (this.controller.element.hasAttribute(this.ifAttribute))
      this.updateIfElement(this.controller.element)

    this.controller.element
      .querySelectorAll(this.ifMatcher)
      .forEach(this.updateIfElement.bind(this))
  }

  updateIfElement(element) {
    if (element.tagName !== "TEMPLATE")
      return console.error(`[Reactor] The ${this.ifAttribute} attribute must be used on `,
                           `<template> elements.`, element)

    if (element.hasAttribute(this.textAttribute))
      return console.error(`[Reactor] The <template> element cannot have a ${this.textAttribute} ` +
                           `attribute. Suggestion: Define a <div> with ${this.textAttribute} ` +
                           `inside the <template>.`, element)

    if (element.hasAttribute(this.htmlAttribute))
      return console.error(`[Reactor] The <template> element cannot have a ${this.htmlAttribute} ` +
                           `attribute. Suggestion: Define a <div> with ${this.htmlAttribute} ` +
                           `inside the <template>.`, element)

    if (!element.hasAttribute(this.ifSrcAttribute))
      element.setAttribute(this.ifSrcAttribute, this.nextIfSrcAttribute())

    const property   = element.getAttribute(this.ifAttribute)
    const value      = this.getControllerValue(property)
    const identifier = element.getAttribute(this.ifSrcAttribute)
    const clone      = document.querySelector(`[${this.ifDstAttribute}="${identifier}"]`)

    if (this.isVisible(value) && clone === null) {
      const clone = element.content.cloneNode(true)

      if (clone.childElementCount !== 1)
        return console.error(`[Reactor] The <template> element requires exactly 1 root node, ` +
                             `but has ${clone.childElementCount}. Element:`, element, `Clone:`, clone)

      clone.firstElementChild.setAttribute(this.ifDstAttribute, identifier)
      element.parentNode.insertBefore(clone, element.nextSibling)
      this.update()
    } else if(!this.isVisible(value) && clone !== null) {
      clone.remove()
    }
  }

  getElementValue(element) {
    switch (element.type) {
    case "checkbox":
      return element.checked
    default:
      return element.value
    }
  }

  getControllerValue(property) {
    if (typeof property !== "string")
      return null
    else if (typeof this.controller[property] === "function")
      return this.controller[property].call(this.controller)
    else
      return this.controller[`${property}Value`]
  }

  setControllerValue(property, value) {
    const key = `${property}Value`

    this.controller[key]          = value
    this.setControllerValues[key] = true
  }

  isControllerValueUnset(property) {
    return this.setControllerValues[`${property}Value`] !== true
  }

  static RENDERABLE = {"number": true, "string": true}

  isRenderable(value) {
    return this.constructor.RENDERABLE[(typeof value)]
  }

  static INVISIBLE = {false: true, "": true, null: true, undefined: true, NaN: true}

  isVisible(value) {
    return !this.constructor.INVISIBLE[value]
  }

  static ifSrcAttributeIdentifier = 0

  nextIfSrcAttribute() {
    return this.constructor.ifSrcAttributeIdentifier += 1
  }

  isNaNType(value) {
    return typeof value === "number" && isNaN(value)
  }
}
