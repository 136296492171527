// Turbo Drive Scroll Hack
//
// Overrides the browsers window.scrollTo function to prevent Turbo Drive from performing
// any scrolling operations for the duration of a typical page transition.
//
// Temporarily disables the window.scrollTo function in the following events
// in order to maintain the current scroll position:
//
//   - The visit href is identical to the current href
//   - The clicked link contains a data-turbo-drive-scroll=false attribute
//   - The successful form submission redirects to an href identical to the current href
//
// On each turbo:load event (which occurs after the above-mentioned events), the original scroll
// functionality is restored so any part of the application that requires the use of window.scrollTo
// can continue to use it.

import { Turbo } from "@hotwired/turbo-rails"

let y = 0

const originalScrollTo = window.scrollTo;
const noopScrollTo = () => {}
const enableScrollTo = () => window.scrollTo = originalScrollTo
const disableScrollTo = () => window.scrollTo = noopScrollTo
const recordScrollPosition = () => y = window.scrollY
const resetScrollPosition = () => y = 0
const scrollSelector = "[data-turbo-drive-scroll=false]"

document.addEventListener("DOMContentLoaded", event => {
  document.addEventListener("turbo:refresh", event => {
    recordScrollPosition()
    disableScrollTo()
    Turbo.visit(window.location.pathname)
  })

  document.addEventListener("turbo:before-visit", event => {
    if (event.detail.url === window.location.href) {
      recordScrollPosition()
      disableScrollTo()
    }
  })

  document.addEventListener("turbo:click", event => {
    if (event.target.dataset.turboDriveScroll === "false") {
      recordScrollPosition()
      disableScrollTo()
    }
  })

  document.addEventListener("turbo:submit-end", event => {
    if (event.detail.success &&
        event.detail.formSubmission.mustRedirect &&
        event.detail.fetchResponse.response.url === window.location.href) {
      recordScrollPosition()
      disableScrollTo()
    }
  })

  document.addEventListener("turbo:load", () => {
    setTimeout(enableScrollTo, 0)
    setTimeout(() => window.scrollTo(0, y), 0)
    setTimeout(() => resetScrollPosition(), 0)
  })
})
