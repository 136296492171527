import { Turbo } from "@hotwired/turbo-rails"
import { Controller } from "@hotwired/stimulus"
import { csrfToken } from "../lib/helpers"

// Intercepts click events on the element and performs an HTTP request using fetch().
//
// The endpoint is determined by the element.
// In the event that the element is a link (<a>) then the href attribute is used.
// On any other element, it expects data-submit-action to be present.
//
// The method is determined by data-submit-method. Defaults to GET.
//
// Sets the Content-Type to text/vnd.turbo-stream.html and applies the CSRF for authenticity.
//
// Expects a turbo stream response and will process it.
//
// CONSIDER: Allow parameters to be set.
//
export default class extends Controller {
  connect() {
    this.element.addEventListener("click", event => this.handleClick(event))
  }

  async handleClick(event) {
    event.preventDefault()

    const response = await fetch(
      this.endpoint(event.target),
      {
        method: this.method(event.target),
        headers: {
          "X-CSRF-Token": csrfToken(),
          "Content-Type": "text/vnd.turbo-stream.html"
        }
      }
    )

    Turbo.renderStreamMessage(await response.text())
  }

  method(element) {
    return element.dataset.submitMethod || "get"
  }

  endpoint(element) {
    if (element.href)
      return element.href
    else
      return element.dataset.submitAction
  }
}
