import { Controller } from "@hotwired/stimulus"
import Reactor from "../../lib/reactor"

export default class extends Controller {
  static values = {
    checkupFrequency: Number,
    baseclockRate: Number,
    overclockRate: Number,
    baseclockRateMonthly: Number,
    overclockRateMonthly: Number,
    customDomain: String,
  }

  static protocolPattern = new RegExp("^https?://")

  connect() {
    new Reactor(this)
  }

  customDomainValueChanged = (value) => {
    this.customDomainValue = value.replace(this.constructor.protocolPattern, "")
  }

  description() {
    if (this.isPaid) {
      if (this.isBaseclock) {
        return this.baseDescription + this.baseclockDescription
      } else {
        return this.baseDescription + this.overclockDescription
      }
    } else {
      return this.baseDescription
    }
  }

  get baseDescription() {
    return `<div>Perform a checkup operation once per ${this.checkupFrequencyValue} seconds</div>`
  }

  get baseclockDescription() {
    return `<div class="leading-10">
              Billed at a <span class="underline">baseclock rate</span> of
              $${this.baseclockRateValue}/hour (roughly $${this.baseclockRateMonthlyValue}/month)
            </div>`
  }

  get overclockDescription() {
    return `<div class="leading-10">
             ${this.important}
             Billed at an <span class="underline">overclock rate</span> of
             $${this.overclockRateValue}/hour (roughly $${this.overclockRateMonthlyValue}/month)
           </div>`
  }

  get important() {
    if (this.baseclockRateMonthlyValue !== this.overclockRateMonthlyValue)
      return `<span class="text-red-500">Important: </span>`
    else
      return ``
  }

  get isPaid() {
    return this.baseclockRateValue > 0 || this.overclockRateValue > 0
  }

  get isBaseclock() {
    return this.checkupFrequencyValue === 60
  }
}
