/// Stripe Test Cards
//
// 4242424242424242
// Succeeds and immediately processes the payment.
//
// 4000002500003155
// Requires authentication for the initial purchase, but succeeds for subsequent payments
// (including off-session ones) as long as the card is setup with setup_future_usage.
//
// 4000002760003184
// Requires authentication for the initial purchase, and fails for subsequent payments
// (including off-session ones) with an authentication_required decline code.
//
// 4000008260003178
// Requires authentication for the initial purchase, but fails for subsequent payments
// (including off-session ones) with an insufficient_funds decline code.
//
// 4000000000009995
// Always fails (including the initial purchase) with a decline code of insufficient_funds.

import { Controller } from "@hotwired/stimulus"
import { loadStripe } from "@stripe/stripe-js"
import Reactor from "../../lib/reactor"

export default class extends Controller {
  static values = {
    pk: String,
    secret: String,
    vatCountries: Array,
    company: String,
    setupIntentId: String,
    class: String,
    vatNumber: String,
    firstName: String,
    lastName: String,
    email: String,
    phone: String,
    city: String,
    country: String,
    line1: String,
    line2: String,
    zip: String,
    state: String,
  }

  initialize() {
    this.updateElements = this.updateElements.bind(this)
  }

  async connect() {
    new Reactor(this)
    this.prepareForm()
    this.listen()
  }

  disconnect() {
    this.ignore()
  }

  async prepareForm() {
    if (this.setupIntentIdValue) {
      this.addSetupIntentIdNode()
    } else {
      this.stripe = await loadStripe(this.pkValue)
      this.elements = this.stripe.elements()
      this.cardNumber = this.elements.create("cardNumber", this.elementOptions)
      this.cardExpiry = this.elements.create("cardExpiry", this.elementOptions)
      this.cardCvc = this.elements.create("cardCvc", this.elementOptions)
      this.cardNumber.mount("#stripe-card-number")
      this.cardExpiry.mount("#stripe-card-expiry")
      this.cardCvc.mount("#stripe-card-cvc")
    }
  }

  updateElements() {
    this.cardNumber?.update(this.elementOptions)
    this.cardExpiry?.update(this.elementOptions)
    this.cardCvc?.update(this.elementOptions)
  }

  get elementOptions() {
    return  {
      style: {
        base: {
          iconColor: '#c4f0ff',
          color: this.isDarkMode ? "#E6E7EB" : "#000",
          fontWeight: '500',
          fontFamily: 'Inter, sans-serif',
          fontSize: '16px',
          fontSmoothing: 'antialiased',
          ':focus': {
            color: '#3C76BC'
          }
        },
        invalid: {
          iconColor: '#DC2627',
          color: '#DC2627',
        }
      }
    }
  }

  get isDarkMode() {
    return !!(window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches)
  }

  countryValueChanged = () => {
    if (!this.vatRequired())
      this.vatNumberValue = ""
  }

  vatNumberValueChanged = () => {
    this.vatNumberValue = this.vatNumberValue.toUpperCase().replace(this.vatNumberRegExp, "")
  }

  get vatNumberRegExp() {
    return new RegExp(`^(${this.vatCountriesValue.join("|")})`)
  }

  vatRequired() {
    return this.vatCountriesValue.includes(this.countryValue)
  }

  async listen() {
    window.matchMedia("(prefers-color-scheme: dark)").addEventListener("change", this.updateElements)

    this.element.addEventListener("submit", async (event) => {
      this.removeBaseError()

      if (!this.setupIntentIdValue) {
        event.preventDefault()

        if (await this.acquirePaymentIntent()) {
          this.addSetupIntentIdNode()
          this.element.dispatchEvent(new CustomEvent("submit", { bubbles: true }))
        }
      }
    })
  }

  ignore() {
    window.matchMedia("(prefers-color-scheme: dark)").removeEventListener("change", this.updateElements)
  }

  async acquirePaymentIntent() {
    const {setupIntent, error} = await this.stripe.confirmCardSetup(
      this.secretValue,
      {
        payment_method: {
          card: this.cardNumber,
          billing_details: this.buildBillingDetails()
        }
      }
    )

    return new Promise(resolve => {
      if (error) {
        this.setBaseError(error.message)
        this.element.dispatchEvent(new CustomEvent("spinner:to-button"))
        resolve(false)
      } else {
        if (setupIntent.status === "succeeded") {
          this.setupIntentIdValue = setupIntent.id
          resolve(true)
        } else {
          resolve(false)
        }
      }
    })
  }

  buildBillingDetails() {
    let billingDetails = { address: { } }

    if (this.firstNameValue && this.lastNameValue)
      billingDetails.name = `${this.firstNameValue} ${this.lastNameValue}`

    if (this.emailValue)
      billingDetails.email = this.emailValue

    if (this.phoneValue)
      billingDetails.phone = this.phoneValue

    if (this.cityValue)
      billingDetails.address.city = this.cityValue

    if (this.countryValue)
      billingDetails.address.city = this.countryValue

    if (this.line1Value)
      billingDetails.address.line1 = this.line1Value

    if (this.line2Value)
      billingDetails.address.line2 = this.line2Value

    if (this.zipValue)
      billingDetails.address.postal_code = this.zipValue

    if (this.stateValue)
      billingDetails.address.state = this.stateValue

    return billingDetails
  }

  get baseErrorTarget() {
    return this.element.querySelector("[data-id=base-error]")
  }

  setBaseError(message) {
    this.baseErrorTarget.innerText = message
  }

  removeBaseError() {
    this.baseErrorTarget.innerText = ""
  }

  addSetupIntentIdNode() {
    const setupIntentIdNode = document.createElement("input")
    setupIntentIdNode.type = "hidden"
    setupIntentIdNode.name = "input[setup_intent_id]"
    setupIntentIdNode.value = this.setupIntentIdValue
    this.element.prepend(setupIntentIdNode)
  }
}
