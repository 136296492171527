import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { max: Number }

  connect() {
    const input = this.element.querySelector("input[type=number]")
    const range = this.element.querySelector("input[type=range]")

    if (input.value > this.maxValue) {
      range.max = input.value
    } else {
      range.max = this.maxValue
    }

    input.addEventListener("input", () => {
      if (input.value > this.maxValue) {
        range.max = input.value
      }
    })
  }
}
