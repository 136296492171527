import { sleep } from "./helpers"


// DOM

function injectBeacon() {
  !function(e,t,n){function a(){var e=t.getElementsByTagName("script")[0],n=t.createElement("script");n.type="text/javascript",n.async=!0,n.src="https://beacon-v2.helpscout.net",e.parentNode.insertBefore(n,e)}if(e.Beacon=n=function(t,n,a){e.Beacon.readyQueue.push({method:t,options:n,data:a})},n.readyQueue=[],"complete"===t.readyState)return a();e.attachEvent?e.attachEvent("onload",a):e.addEventListener("load",a,!1)}(window,document,window.Beacon||function(){});
}


// Class

class Beacon {
  get Beacon() {
    return window.Beacon
  }

  get data() {
    return document.getElementById("beacon-data")?.dataset
  }

  get container() {
    return document.getElementById("beacon-container")
  }

  constructor() {
    injectBeacon()
    document.addEventListener("turbo:load", () => this.init())
  }

  init() {
    if (this.data && this.data.beaconId && !this.container) {
      this.Beacon("init", this.data.beaconId)
      this.Beacon("identify", JSON.parse(atob(this.data.beaconData)))
    }
  }

  open() {
    this.Beacon("open")
  }

  async docs() {
    this.open()
    await sleep(100)
    this.Beacon("navigate", "/")
  }

  async chat() {
    this.open()
    await sleep(100)
    this.Beacon("navigate", "/ask/message/")
  }

  async suggest(ids) {
    this.open()
    await sleep(2000)
    this.Beacon("suggest", ids.filter(id => id))
  }

  async article(id) {
    this.open()
    await sleep(100)
    this.Beacon("article", id)
  }
}

const beacon = new Beacon()


// Observer

function docsEventHandler(event) {
  event.preventDefault()
  beacon.docs()
}

function bindDocs(element) {
  if (element.getAttribute("data-beacon") === "docs")
    element.addEventListener("click", docsEventHandler)
}

function chatEventHandler(event) {
  event.preventDefault()
  beacon.chat()
}

function bindChat(element) {
  if (element.getAttribute("data-beacon") === "chat")
    element.addEventListener("click", chatEventHandler)
}

function suggestEventHandler(event) {
  const ids = event.target.getAttribute("data-beacon-suggest").split(/\s+|\n+/)
  beacon.suggest(ids)
  event.preventDefault()
}

function bindSuggest(element) {
  if (!element.hasAttribute("data-beacon-suggest")) return
  element.addEventListener("click", suggestEventHandler)
}

function articleEventHandler(event) {
  const id = event.target.getAttribute("data-beacon-article")
  beacon.article(id)
  event.preventDefault()
}

function bindArticle(element) {
  if (!element.hasAttribute("data-beacon-article")) return
  element.addEventListener("click", articleEventHandler)
}

function bindAll(element) {
  bindDocs(element)
  element.querySelectorAll("[data-beacon=docs]").forEach(bindDocs)
  bindChat(element)
  element.querySelectorAll("[data-beacon=chat]").forEach(bindChat)
  bindSuggest(element)
  element.querySelectorAll("[data-beacon-suggest]").forEach(bindSuggest)
  bindArticle(element)
  element.querySelectorAll("[data-beacon-article]").forEach(bindArticle)
}

let observer;

export function observe() {
  observer?.disconnect()

  const body   = document.querySelector("[data-turbo-drive-body]")
  const config = { childList: true, subtree: true }

  observer = new MutationObserver((mutationsList, observer) => bindAll(body))
  observer.observe(body, config)

  bindAll(body)
}

export default { beacon, observe }
