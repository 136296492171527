import { Controller } from "@hotwired/stimulus"
import { setClass } from "../lib/helpers"

export default class extends Controller {
  static targets = ["links"]
  static tabBaseClasses = [
    "flex-1",
    "transition",
    "flex",
    "justify-center",
    "items-center",
    "h-12",
    "uppercase",
    "text-gray-900",
    "text-sm",
    "border-l",
    "first:border-l-0",
    "border-t",
    "border-b",
    "dark:border-gray-900",
  ]
  static tabInactiveClasses = this.tabBaseClasses.concat([
    "bg-gray-100",
    "dark:bg-azure-900",
    "hover:bg-gray-50",
    "dark:text-azure-50",
    "dark:hover:bg-azure-800",
    "focus:bg-gray-50",
  ])
  static tabActiveClasses = this.tabBaseClasses.concat([
    "border-b-azure-50",
    "dark:border-b-gray-800",
    "text-azure-700",
    "dark:text-azure-200",
  ])

  initialize() {
    this.setupLink = this.setupLink.bind(this)
    this.linkClicked = this.linkClicked.bind(this)
  }

  connect() {
    this.linkTargets.forEach(this.setupLink)
    this.setupActiveLink(this.element.querySelector("[data-active=true]"))
  }

  setupLink(link) {
    this.toggleActive(link, false)
    link.addEventListener("click", this.linkClicked)
  }

  setupActiveLink(link) {
    this.toggleActive(link, true)
  }

  toggleActive(link, active) {
    if (active) {
      setClass(link, link.dataset.class, this.constructor.tabActiveClasses)
    } else {
      setClass(link, link.dataset.class, this.constructor.tabInactiveClasses)
    }
  }

  linkClicked(event) {
    this.renderSpinner()
    this.linkTargets.forEach(link => {
      link.removeEventListener("click", this.linkClicked)
      link.addEventListener("click", this.preventDefault)
      this.toggleActive(link, false)
    })
    this.toggleActive(event.target, true)
  }

  renderSpinner() {
    this.contentTarget.innerHTML = `
      <div class="flex justify-center items-center w-full py-20">
        <svg data-controller="spinner" class="w-12 h-12 text-azure-700 dark:text-azure-300"></svg>
      </div>
    `
  }

  preventDefault(event) {
    event.preventDefault()
  }

  get contentTarget() {
    return document.getElementById("tabs-body")
  }

  get linkTargets() {
    return this.linksTarget.querySelectorAll("a")
  }
}
