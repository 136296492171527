import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static duration = 300
  static targets = ["box", "title", "content"]
  static spinner = `
    <div class="flex justify-center items-center w-full py-20">
      <svg data-controller="spinner" class="w-12 h-12 text-azure-700 dark:text-azure-300"></svg>
    </div>
  `

  static visibilityStates = {
    element: {
      visible: ["visible", "opacity-100"],
      invisible: ["invisible", "opacity-0"],
    },
    boxTarget: {
      visible: ["translate-y-0"],
      invisible: ["translate-y-10"],
    },
  }

  initialize() {
    this.open = this.open.bind(this)
    this.close = this.close.bind(this)
    this.keydown = this.keydown.bind(this)
    this.clearContent = this.clearContent.bind(this)
    this.enableTransitions = this.enableTransitions.bind(this)
  }

  connect() {
    this.element.addEventListener("modal:open", this.open)
    this.element.addEventListener("modal:close", this.close)
    setTimeout(this.enableTransitions, this.constructor.duration)
  }

  // Hack: Removes the occassional (unintended) animation on page load.
  enableTransitions() {
    const classes = ["transition-all", "ease-in-out", `duration-${this.constructor.duration}`]
    this.element.classList.add(...classes)
    this.boxTarget.classList.add(...classes)
  }

  open({detail: {title, url}}) {
    this.contentTarget.innerHTML = this.constructor.spinner
    this.titleTarget.innerText = title
    this.contentTarget.setAttribute("src", url)
    this.toggleVisibility(true)
    document.addEventListener("keydown", this.keydown)
  }

  close() {
    this.toggleVisibility(false)
    this.contentTarget.removeAttribute("src")
    document.removeEventListener("keydown", this.keydown)
    setTimeout(this.clearContent, this.constructor.duration)
  }

  toggleVisibility(visible) {
    for (const element in this.constructor.visibilityStates) {
      const states = this.constructor.visibilityStates[element]

      if (visible) {
        this[element].classList.remove(...states.invisible)
        this[element].classList.add(...states.visible)
      } else {
        this[element].classList.remove(...states.visible)
        this[element].classList.add(...states.invisible)
      }
    }
  }

  clearContent() {
    this.contentTarget.innerHTML = ""
  }

  keydown(event) {
    if (event.key === "Escape")
      this.close()
  }
}
