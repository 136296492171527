import { Controller } from "@hotwired/stimulus"
import { flash } from "../flash_component_controller"
import Sortable from "sortablejs"

export default class extends Controller {
  static values = {
    managerId: String
  }

  connect() {
    if (this.bodyTarget === null)
      return

    this.sortable = Sortable.create(
      this.element,
      {
        handle: ".TIME-RANGE-sortable-handle",
        ghostClass: "TIME-RANGE-sortable-ghost",
        chosenClass: "TIME-RANGE-sortable-chosen",
        dragClass: "TIME-RANGE-sortable-drag",
        onUpdate: this.onUpdate.bind(this),
      }
    )

    this.currentOrder = this.sortable.toArray()
  }

  async onUpdate(e) {
    const data = new FormData()

    data.append("authenticity_token", this.authenticityToken)
    this.sortable.toArray().forEach(id => data.append("ids[]", id))

    try {
      const response = await fetch(
        `/time_ranges/${this.managerIdValue}/sort`,
        {
          body: data,
          method: "post",
        }
      )

      if (response.ok) {
        flash("notice", "Time Range priority has been adjusted")
        this.currentOrder = this.sortable.toArray()
      } else {
        flash("alert", "Failed to sort time ranges:", response.statusText, `(${response.status})`)
        this.sortable.sort(this.currentOrder)
      }
    } catch(err) {
      flash("alert", "Failed to sort time ranges:", "Couldn't connect to server.")
      this.sortable.sort(this.currentOrder)
    }
  }

  get authenticityToken() {
    return document.querySelector("meta[name=csrf-token]").content
  }
}
