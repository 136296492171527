import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    accept: String,
    busy: Boolean,
    onEnd: String,
  }

  connect() {
    this.element.addEventListener("click", async (event) => {
      event.preventDefault()

      if (this.busyValue) return

      this.busyValue = true

      try {
        const response = await fetch(this.element.href, this.httpOptions)
        const blob     = await response.blob()
        const data     = URL.createObjectURL(blob)

        window.open(data)
      } catch(e) { }

      if (this.onEndEvent)
        this.element.dispatchEvent(this.onEndEvent)

      this.busyValue = undefined
    })
  }

  get httpOptions() {
    return {
      responseType: "arraybuffer",
      headers: {
        Accept: this.acceptValue,
      }
    }
  }

  get onEndEvent() {
    if (this.onEndValue)
      return new CustomEvent(this.onEndValue, { bubbles: true })
    else
      return null
  }
}
