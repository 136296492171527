import { Controller } from "@hotwired/stimulus"
import Chart from "chart.js/auto"

const activeClasses = ["!bg-gray-100", "dark:!bg-gray-700"]

export default class extends Controller {
  static values = {
    chartStates: Array,
    currentTab: String,
    oldestMonthDateHour: Number,
    oldestDayDateHour: Number,
  }

  static targets = [
    "tab",
    "canvasWrapper",
  ]

  static monthNumberToName = {
    "01": "Jan",
    "02": "Feb",
    "03": "Mar",
    "04": "Apr",
    "05": "May",
    "06": "Jun",
    "07": "Jul",
    "08": "Aug",
    "09": "Sep",
    "10": "Oct",
    "11": "Nov",
    "12": "Dec",
  }

  initialize() {
    this.updateOptions = this.updateOptions.bind(this)
  }

  connect() {
    if (["", "day"].includes(this.currentTabValue)) {
      this.showDay({target: this.element.querySelector("[data-action='click->chart-states#showDay']")})
    }
    else {
      this.showMonth({target: this.element.querySelector("[data-action='click->chart-states#showMonth']")})
    }

    this.observeColorSchemeChange()
    this.clearChartFrameHeight()
    this.interval = setInterval(this.reloadChartFrame.bind(this), 15*1000)
  }

  disconnect() {
    this.ignoreColorSchemeChange()
    clearInterval(this.interval)
  }

  reloadChartFrame() {
    const reloadURL = this.reloadURL()
    this.setChartFrameHeight()
    this.chartFrame.src = null
    this.chartFrame.src = reloadURL
  }

  reloadURL() {
    const url = new URL(this.chartFrame.src)
    return `${url.origin + url.pathname}?current_tab=${this.currentTabValue}`
  }

  get chartFrame() {
    return document.getElementById("chart_frame")
  }

  setChartFrameHeight() {
    this.chartFrame.style.height = `${this.chartFrame.clientHeight}px`
  }

  clearChartFrameHeight() {
    setTimeout(() =>  this.chartFrame.style.height = "", 0)
  }

  observeColorSchemeChange() {
    window.matchMedia("(prefers-color-scheme: dark)").addEventListener("change", this.updateOptions)
  }

  ignoreColorSchemeChange() {
    window.matchMedia("(prefers-color-scheme: dark)").addEventListener("change", this.updateOptions)
  }

  updateOptions() {
    const chart = this.chart

    if (chart) {
      chart.options = this.chartOptions
      chart.update()
    }
  }

  showMonth(e) {
    this.tabTargets.forEach(this.makeTabInactive)
    this.makeTabActive(e.target)
    this.renderMonth()
  }

  showDay(e) {
    this.tabTargets.forEach(this.makeTabInactive)
    this.makeTabActive(e.target)
    this.renderDay()
  }

  makeTabActive(tab) {
    tab?.classList.add(...activeClasses)
  }

  makeTabInactive(tab) {
    tab?.classList.remove(...activeClasses)
  }

  renderChart(data) {
    if (this.chart)
      this.updateChart(data)
    else
      this.createChart(data)
  }

  createChart(data) {
    const config = {
      type: "bar",
      data: data,
      options: this.chartOptions,
    }

    const canvas = document.createElement("canvas")
    this.chart = new Chart(canvas, config)

    if (this.hasCanvasWrapperTarget) {
      this.canvasWrapperTarget.innerHTML = ""
      this.canvasWrapperTarget.appendChild(canvas)
    }
  }

  get isDarkMode() {
    return !!(window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches)
  }

  get chartOptions() {
    let tooltip = {
      titleColor: "rgba(55, 65, 81, 1)",
      bodyColor: "rgba(55, 65, 81, 1)",
      backgroundColor: "rgba(249, 250, 251, 1)",
    }

    let scales = {
      x: {
        ticks: {
          color: "rgba(48, 95, 158, 1)",
        },
        grid: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
        ticks: {
          color: "rgba(48, 95, 158, 1)",
        },
        grid: {
          color: "rgba(249, 250, 251, 1)",
          tickColor: "rgba(48, 95, 158, 1)",
        },
      }
    }

    if (this.isDarkMode) {
      tooltip.titleColor = "rgba(255, 255, 255, 1)"
      tooltip.bodyColor = "#FFF"
      tooltip.backgroundColor = "#263142"
      scales.y.grid.color = "#1D1F23"
      scales.y.grid.tickColor = "#1D1F23"
      scales.y.ticks.color = "#FFF"
      scales.x.ticks.color = "#FFF"
    }

    return {
      animation: false,
      plugins: {
        legend: {
          display: false,
        },
        tooltip: tooltip,
      },
      scales: scales,
    }
  }

  updateChart(data) {
    this.chart.data = data
    this.chart.options = this.chartOptions
    this.chart.update()
  }

  destroyChart() {
    this.chart?.destroy()
    delete this.chart
  }

  static bgcolor = "rgba(48, 95, 158, 1)"

  renderDay() {
    this.currentTabValue = "day"

    const states = this.hoursOfDay()
    const labels = states.map(([date_hour, _]) => date_hour.toString().slice(-2) + ":00")
    const datasetData = states.map(([_, average]) => average)
    const data = {
      labels: labels,
      datasets: [{
        label: "average dyno usage",
        data: datasetData,
        backgroundColor: [this.constructor.bgcolor],
        hoverBackgroundColor: [this.constructor.bgcolor],
      }]
    }

    if (datasetData.length) {
      this.renderChart(data)
    } else {
      this.renderEmpty()
    }
  }

  renderMonth() {
    this.currentTabValue = "month"

    const states = this.daysOfMonth()

    const labels = states.map(([date, _]) => {
      const dateString  = date.toString()
      const monthNumber = dateString.slice(4, -2)
      const dayNumber   = dateString.slice(6)
      const monthName   = this.constructor.monthNumberToName[monthNumber]

      return `${monthName} ${dayNumber}`
    })

    const datasetData = states.map(([_, average]) => average)

    const data = {
      labels: labels,
      datasets: [{
        label: "average dyno usage",
        data: datasetData,
        backgroundColor: [this.constructor.bgcolor],
        hoverBackgroundColor: [this.constructor.bgcolor],
      }]
    }

    if (datasetData.length) {
      this.renderChart(data)
    } else {
      this.renderEmpty()
    }
  }

  renderEmpty() {
    this.destroyChart()
    if (this.hasCanvasWrapperTarget)
      this.canvasWrapperTarget.innerHTML = `
        <div class="text-center uppercase text-gray-900 dark:text-gray-50">
          Insufficient data
        </div>`
  }

  hoursOfDay() {
    return this.chartStatesValue.filter(([date_hour, average]) => {
      return date_hour > this.oldestDayDateHourValue
    }).reverse()
  }

  daysOfMonth() {
    // step 0: [datehour, average]

    // ---

    // step 1: filter by date
    const filteredData = this.chartStatesValue.filter(([date_hour, average]) => {
      return date_hour > this.oldestMonthDateHourValue
    })

    // step 2: {date: [average]}
    const dateAggregation = filteredData.reduce((a, [date_hour, average]) => {
      const date = date_hour.toString().slice(0, -2)

      a[date] ||= []
      a[date].push(average)

      return a
    }, {})

    // step 3: [date, average]
    const summerizedAverages =  Object.entries(dateAggregation).reduce((a, [date, averages]) => {
      const average = (averages.reduce((a, avg) => a + avg) / averages.length).toFixed(2)

      a.push([parseInt(date), parseFloat(average)])

      return a
    }, [])

    // step 4: sort by date
    summerizedAverages.sort((a, b) => a[0] - b[0])

    return summerizedAverages
  }
}
