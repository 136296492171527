import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.waitForTurnstile(this.initializeTurnstile.bind(this))
  }

  get captchaTarget() {
    return this.element.querySelector('[data-sitekey]');
  }

  get submitTarget() {
    return this.element.querySelector('button[type="submit"]');
  }

  initializeTurnstile() {
    if (this.captchaTarget == null || this.submitTarget == null) {
      return;
    }

    this.submitTarget.setAttribute('disabled', 'disabled');

    turnstile.render(this.captchaTarget, {
      callback: (token) => this.submitTarget.removeAttribute('disabled')
    });
  };

  waitForTurnstile(callback) {
    if (typeof window.turnstile === 'undefined') {
      setTimeout(() => this.waitForTurnstile(callback), 100);
    } else {
      callback();
    }
  }
}
