import { Controller } from "@hotwired/stimulus"

// @example
//   <div data-controller="embed-spinner" data-class="w-12 h-12 text-gray-500">
//     <!-- replace this content with a spinner -->
//   </div>
//
export default class extends Controller {
  static values = {class: String}

  connect() {
    this.elementOriginalHTML = this.element.innerHTML
    this.elementSpinnerHTML = `<svg data-controller="spinner" class="${this.classValue || "h-3/4"}" />`

    this.element.addEventListener("click", () => this.toSpinner())
    this.element.addEventListener("spinner:to-spinner", () => this.toSpinner())
    this.element.addEventListener("spinner:to-element", () => this.toElement())
  }

  toSpinner() {
    this.element.innerHTML = this.elementSpinnerHTML
  }

  toElement() {
    this.element.innerHTML = this.elementOriginalHTML
  }
}
