import { Controller } from "@hotwired/stimulus"

// Spinner controller intended for use with form elements.
//
// Note that button_to produces a form under the hood and is therefore able to use this controller.
//
// @example
//   <%= form_for(..., data: {controller: "form--spinner"}) {} %>
//   <%= button_to(..., data: {controller: "form--spinner"}) %>
//
export default class extends Controller {
  connect() {
    if (this.button !== null)
      setTimeout(() => this.configure(), 0)
  }

  configure() {
    this.buttonOriginalHTML = this.button.innerHTML
    this.buttonSpinnerHTML = `<svg data-controller="spinner" class="h-3/4" />`
    this.buttonBlankHTML = ""

    this.form.addEventListener("submit", () => this.toSpinner())
    this.form.addEventListener("turbo:submit-start", () => this.toSpinner())
    this.form.addEventListener("turbo:submit-end", () => this.toButton())
    this.form.addEventListener("spinner:to-spinner", () => this.toSpinner())
    this.form.addEventListener("spinner:to-button", () => this.toButton())
    this.form.addEventListener("spinner:to-blank", () => this.toBlank())
  }

  toSpinner() {
    this.button.innerHTML = this.buttonSpinnerHTML
  }

  toButton() {
    this.button.innerHTML = this.buttonOriginalHTML
  }

  toBlank() {
    this.button.innerHTML = this.buttonBlankHTML
  }

  get form() {
    if (this.element.tagName === "FORM")
      return this.element
    else
      return this.element.closest("form")
  }

  get button() {
    if (this.element.type === "submit")
      return this.element
    else
      return this.element.querySelector("button[type=submit]")
  }
}
