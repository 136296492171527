import { Controller } from "@hotwired/stimulus"
import { impermanent } from "../flash_component_controller"

// On successful form submissions, dispatches the flash:impermanent event to the flash component controller.
// This results in the data-turbo-permanent being removed from the flash element, allowing flash
// messages coming from the server to overwrite and reinitiate the current flash element.
//
export default class extends Controller {
  connect() {
    this.form?.addEventListener("turbo:submit-end", (event) => {
      if (event.detail.success)
        impermanent()
    })
  }

  get form() {
    if (this.element.tagName === "FORM")
      return this.element
    else
      return this.element.closest("form")
  }
}
