import { Controller } from "@hotwired/stimulus"
import { pluralize } from "../../lib/helpers"
import Reactor from "../../lib/reactor"

export default class extends Controller {
  static values = {
    name: String,
    type: String,
    minimum: Number,
    maximum: Number,
    ratio: Number,
    lastMinutes: Number,
    minimumLatency: Number,
    maximumLatency: Number,
    minimumResponseTime: Number,
    maximumResponseTime: Number,
    minimumConnectTime: Number,
    maximumConnectTime: Number,
    minimumQueueTime: Number,
    maximumQueueTime: Number,
    minimumLoad: Number,
    maximumLoad: Number,
    minimumApdex: Number,
    maximumApdex: Number,
    aggregation: String,
    percentile: Number,
    downscaleQuantity: Number,
    upscaleQuantity: Number,
    downscaleSensitivity: Number,
    upscaleSensitivity: Number,
    downscaleTimeout: Number,
    upscaleTimeout: Number,
    downscaleLimit: Number,
    upscaleOnInitialJob: Boolean,
    upscaleLimit: Number,
    scaleUpOn503: Boolean,
    url: String,
    notify: Boolean,
    notifyQuantity: Number,
    notifyAfter: Number,
  }

  static targets = [
    "minimum",
    "maximum",
  ]

  static types = [
    "Manager::Web::HireFire::RequestQueueTime",
    "Manager::Worker::HireFire::JobQueue",
    "Manager::Worker::HireFire::JobLatency",
    "Manager::Web::Logplex::Load",
    "Manager::Web::Logplex::ResponseTime",
    "Manager::Web::Logplex::ConnectTime",
    "Manager::Web::Logplex::QueueTime",
    "Manager::Web::Logplex::RPM",
    "Manager::Web::NewRelic::V2::Apdex",
    "Manager::Web::NewRelic::V2::ResponseTime",
    "Manager::Web::NewRelic::V2::RPM",
    "Manager::Web::NewRelic::V1::Apdex",
    "Manager::Web::NewRelic::V1::ResponseTime",
    "Manager::Web::NewRelic::V1::RPM",
    "Manager::Web::HireFire::ResponseTime",
  ]

  initialize() {
    this.defineTypeMatchers()
  }

  connect() {
    new Reactor(this)
  }

  defineTypeMatchers() {
    this.constructor.types.forEach((type) => {
      const name = type.replaceAll("::", "_")
      this[name] = () => this.typeValue === type
    })
  }

  typeValueChanged() {
    this.updateAttributesForMinimumAndMaximum()
  }

  updateAttributesForMinimumAndMaximum() {
    const types = ["", "Manager::Worker::HireFire::JobQueue", "Manager::Worker::HireFire::JobLatency"]

    if (types.includes(this.typeValue)) {
      this.minimumTargets.forEach(minimumTarget => minimumTarget.min = 0)
      this.maximumTargets.forEach(maximumTarget => maximumTarget.min = 0)
    } else {
      this.minimumTargets.forEach(minimumTarget => minimumTarget.min = 1)
      this.maximumTargets.forEach(maximumTarget => maximumTarget.min = 1)

      if (this.minimumValue === 0)
        this.minimumValue = 1

      if (this.maximumValue === 0)
        this.maximumValue = 1
    }
  }

  minimumDescription() {
    return `Won't scale below ${pluralize(this.minimumValue, "{{n}} dyno", "{{n}} dynos")}.`
  }

  maximumDescription() {
    return `Won't scale above ${pluralize(this.maximumValue, "{{n}} dyno", "{{n}} dynos")}.`
  }

  showUrl() {
    return ["Manager::Web::HireFire::ResponseTime"].includes(this.typeValue)
  }

  show503Url() {
    const includedType = [
      "Manager::Web::NewRelic::V1::Apdex",
      "Manager::Web::NewRelic::V1::ResponseTime",
      "Manager::Web::NewRelic::V2::Apdex",
      "Manager::Web::NewRelic::V2::ResponseTime",
    ].includes(this.typeValue)

    return this.scaleUpOn503Value === true && includedType
  }

  showJobRatioDecrementable() {
    return ["Manager::Worker::HireFire::JobQueue"].includes(this.typeValue)
  }

  jobRatioDescription() {
    if (this.ratioValue === 1)
      return `1 dyno for every job.`
    else
      return `1 dyno for every ${this.ratioValue} jobs.`
  }

  showRpmRatio() {
    return [
      "Manager::Web::Logplex::RPM",
      "Manager::Web::NewRelic::V1::RPM",
      "Manager::Web::NewRelic::V2::RPM",
    ].includes(this.typeValue)
  }

  rpmRatioDescription() {
    if (this.ratioValue === 1)
      return `1 web dyno for every request per minute.`
    else
      return (`1 web dyno for every ${this.ratioValue} requests per minute ` +
              `(${Math.ceil(this.ratioValue/60)} requests per second).`)
  }

  showJobLatency() {
    return this.typeValue == "Manager::Worker::HireFire::JobLatency"
  }

  showResponseTime() {
    return [
      "Manager::Web::Logplex::ResponseTime",
      "Manager::Web::NewRelic::V2::ResponseTime",
      "Manager::Web::NewRelic::V1::ResponseTime",
      "Manager::Web::HireFire::ResponseTime",
    ].includes(this.typeValue)
  }

  minimumJobLatencyDescription() {
    return `Scale down when latency gets below ${this.minimumLatencyValue}s.`
  }

  maximumJobLatencyDescription() {
    return `Scale up when latency exceeds ${this.maximumLatencyValue}s.`
  }

  minimumResponseTimeDescription() {
    return `Scale down when response times get below ${this.minimumResponseTimeValue}ms.`
  }

  maximumResponseTimeDescription() {
    return `Scale up when response times exceed ${this.maximumResponseTimeValue}ms.`
  }

  showConnectTime() {
    return ["Manager::Web::Logplex::ConnectTime"].includes(this.typeValue)
  }

  minimumConnectTimeDescription() {
    return `Scale down when connect times get below ${this.minimumConnectTimeValue}ms.`
  }

  maximumConnectTimeDescription() {
    return `Scale up when connect times exceed ${this.maximumConnectTimeValue}ms.`
  }

  showQueueTime() {
    return [
      "Manager::Web::HireFire::RequestQueueTime",
      "Manager::Web::Logplex::QueueTime",
    ].includes(this.typeValue)
  }

  minimumQueueTimeDescription() {
    return `Scale down when queue times get below ${this.minimumQueueTimeValue}ms.`
  }

  maximumQueueTimeDescription() {
    return `Scale up when queue times exceed ${this.maximumQueueTimeValue}ms.`
  }

  showApdex() {
    return [
      "Manager::Web::NewRelic::V2::Apdex",
      "Manager::Web::NewRelic::V1::Apdex",
    ].includes(this.typeValue)
  }

  minimumApdexDescription() {
    return (`Scale up when the apdex score goes below ` +
            `${(this.minimumApdexValue / 100).toFixed(2)}.`)
  }

  maximumApdexDescription() {
    return (`Scale down when the apdex score is- or exceeds ` +
            `${(this.maximumApdexValue / 100).toFixed(2)}.`)
  }

  showAggregation() {
    return [
      "Manager::Web::Logplex::ResponseTime",
      "Manager::Web::Logplex::ConnectTime",
      "Manager::Web::Logplex::QueueTime",
    ].includes(this.typeValue)
  }

  showPercentile() {
    return this.aggregationValue === "percentile"
  }

  aggregationDescription() {
    if (this.aggregationValue === "average")
      return `Use the average across all dynos.`

    if (this.percentileValue) {
      let nth = ""
      let n = ("" + this.percentileValue)[("" + this.percentileValue).length - 1]

      if (n === "1") { nth = `${this.percentileValue}st` }
      if (n === "2") { nth = `${this.percentileValue}nd` }
      if (n === "3") { nth = `${this.percentileValue}rd` }
      if (nth === "") { nth = `${this.percentileValue}th` }

      return `Use the ${nth} percentile across all dynos.`
    }

    return ``
  }

  showLoad() {
    return ["Manager::Web::Logplex::Load"].includes(this.typeValue)
  }

  minimumLoadDescription() {
    return `Scale down when load goes below ${(this.minimumLoadValue / 100.0).toFixed(2)}.`
  }

  maximumLoadDescription() {
    return `Scale up when load exceeds ${(this.maximumLoadValue / 100.0).toFixed(2)}.`
  }

  lastMinutesDescription() {
    if (this.lastMinutesValue === 1)
      return `Use load metrics of the last minute.`

    return `Use load metrics of the last ${this.lastMinutesValue} minutes.`
  }

  showQuantity() {
    return [
      "Manager::Web::HireFire::RequestQueueTime",
      "Manager::Worker::HireFire::JobLatency",
      "Manager::Web::Logplex::Load",
      "Manager::Web::Logplex::ResponseTime",
      "Manager::Web::Logplex::ConnectTime",
      "Manager::Web::Logplex::QueueTime",
      "Manager::Web::NewRelic::V2::ResponseTime",
      "Manager::Web::NewRelic::V2::Apdex",
      "Manager::Web::NewRelic::V1::ResponseTime",
      "Manager::Web::NewRelic::V1::Apdex",
      "Manager::Web::HireFire::ResponseTime",
    ].includes(this.typeValue)
  }

  downscaleQuantityDescription() {
    return `Scale down ${pluralize(this.downscaleQuantityValue, "{{n}} dyno", "{{n}} dynos")} at a time.`
  }

  upscaleQuantityDescription() {
    return `Scale up ${pluralize(this.upscaleQuantityValue, "{{n}} dyno", "{{n}} dynos")} at a time.`
  }

  showSensitivity() {
    return [
      "Manager::Web::HireFire::RequestQueueTime",
      "Manager::Worker::HireFire::JobQueue",
      "Manager::Worker::HireFire::JobLatency",
      "Manager::Web::Logplex::Load",
      "Manager::Web::Logplex::ResponseTime",
      "Manager::Web::Logplex::ConnectTime",
      "Manager::Web::Logplex::QueueTime",
      "Manager::Web::Logplex::RPM",
      "Manager::Web::NewRelic::V2::ResponseTime",
      "Manager::Web::NewRelic::V2::RPM",
      "Manager::Web::NewRelic::V2::Apdex",
      "Manager::Web::NewRelic::V1::ResponseTime",
      "Manager::Web::NewRelic::V1::RPM",
      "Manager::Web::NewRelic::V1::Apdex",
      "Manager::Web::HireFire::ResponseTime",
    ].includes(this.typeValue)
  }

  downscaleSensitivityDescription() {
    if (this.downscaleSensitivityValue === 1)
      return `Scale immediately after threshold breach.`

    return (`Scale after ${this.downscaleSensitivityValue} consecutive threshold` +
            `${pluralize(this.downscaleSensitivityValue, "breach", "breaches")}.`)
  }

  upscaleSensitivityDescription() {
    if (this.upscaleSensitivityValue === 1)
      return `Scale immediately after threshold breach.`

    return (`Scale after ${this.upscaleSensitivityValue} consecutive threshold` +
            `${pluralize(this.upscaleSensitivityValue, "breach", "breaches")}.`)
  }

  showTimeout() {
    return [
      "Manager::Web::HireFire::RequestQueueTime",
      "Manager::Worker::HireFire::JobQueue",
      "Manager::Worker::HireFire::JobLatency",
      "Manager::Web::Logplex::Load",
      "Manager::Web::Logplex::ResponseTime",
      "Manager::Web::Logplex::ConnectTime",
      "Manager::Web::Logplex::QueueTime",
      "Manager::Web::Logplex::RPM",
      "Manager::Web::NewRelic::V2::ResponseTime",
      "Manager::Web::NewRelic::V2::RPM",
      "Manager::Web::NewRelic::V2::Apdex",
      "Manager::Web::NewRelic::V1::ResponseTime",
      "Manager::Web::NewRelic::V1::RPM",
      "Manager::Web::NewRelic::V1::Apdex",
      "Manager::Web::HireFire::ResponseTime",
    ].includes(this.typeValue)
  }

  downscaleTimeoutDescription() {
    if (this.downscaleTimeoutValue === 0)
      return `Don't delay scaling operations.`

    return (`Wait ${pluralize(this.downscaleTimeoutValue, "{{n}} minute", "{{n}} minutes")} ` +
            `between scaling operations.`)
  }

  upscaleTimeoutDescription() {
    if (this.upscaleTimeoutValue === 0)
      return `Don't delay scaling operations.`

    return (`Wait ${pluralize(this.upscaleTimeoutValue, "{{n}} minute", "{{n}} minutes")} ` +
            `between scaling operations.`)
  }

  showUpscaleOnInitialJob() {
    return ["Manager::Worker::HireFire::JobLatency"].includes(this.typeValue)
  }

  showLimit() {
    return ["Manager::Worker::HireFire::JobQueue"].includes(this.typeValue)
  }

  downscaleLimitDescription() {
    if (this.downscaleLimitValue === 0)
      return `No limit.`

    return (`Limit scaling operations to ` +
            `${pluralize(this.downscaleLimitValue, "{{n}} dyno", "{{n}} dynos")} at a time.`)
  }

  upscaleLimitDescription() {
    if (this.upscaleLimitValue === 0)
      return `No limit.`

    return (`Limit scaling operations to ` +
            `${pluralize(this.upscaleLimitValue, "{{n}} dyno", "{{n}} dynos")} at a time.`)
  }

  showScaleUpOn503() {
    return [
      "Manager::Web::NewRelic::V2::ResponseTime",
      "Manager::Web::NewRelic::V2::Apdex",
      "Manager::Web::NewRelic::V1::ResponseTime",
      "Manager::Web::NewRelic::V1::Apdex",
      "Manager::Web::HireFire::ResponseTime",
    ].includes(this.typeValue)
  }

  showNewRelicCredentials() {
    return [
      "Manager::Web::NewRelic::V2::ResponseTime",
      "Manager::Web::NewRelic::V2::RPM",
      "Manager::Web::NewRelic::V2::Apdex",
      "Manager::Web::NewRelic::V1::ResponseTime",
      "Manager::Web::NewRelic::V1::RPM",
      "Manager::Web::NewRelic::V1::Apdex",
    ].includes(this.typeValue)
  }

  showNewRelicAccountId() {
    return [
      "Manager::Web::NewRelic::V1::ResponseTime",
      "Manager::Web::NewRelic::V1::RPM",
      "Manager::Web::NewRelic::V1::Apdex",
    ].includes(this.typeValue)
  }

  showNewRelicRegion() {
    return [
      "Manager::Web::NewRelic::V2::ResponseTime",
      "Manager::Web::NewRelic::V2::RPM",
      "Manager::Web::NewRelic::V2::Apdex",
    ].includes(this.typeValue)
  }

  notifyQuantityDescription() {
    if (this.notifyQuantityValue === 0)
      return `Notify when running one or more dynos...`

    return (`Notify when running more than ` +
            `${pluralize(this.notifyQuantityValue, "{{n}} dyno", "{{n}} dynos")}...`)
  }

  notifyAfterDescription() {
    if (this.notifyAfterValue === 0)
      return `...immediately.`

    return `...for ${pluralize(this.notifyAfterValue, "an hour", "{{n}} hours")} straight.`
  }
}
