export function pluralize(n, singular, plural) {
  if (n == 1) {
    return singular.replace("{{n}}", n)
  } else {
    return plural.replace("{{n}}", n)
  }
}

export function setClass(element, ...classes) {
  element.setAttribute("class", classes.flat().join(" "))
}

export async function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms))
}

export function isDarkMode() {
  return !!(window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches)
}

export function csrfToken() {
  return document.querySelector("meta[name=csrf-token]").content
}
