import { Controller } from "@hotwired/stimulus"

// Break out of the turbo-frame if the response was successful.
//
// Expects the server to replace the form when validation errors occur, using turbo streams.
//
export default class extends Controller {
  connect() {
    if (this.form)
      this.form.setAttribute("data-turbo-frame", "_top")
  }

  get form() {
    if (this.element.tagName === "FORM")
      return this.element
    else
      return this.element.closest("form")
  }
}
