import { Controller } from "@hotwired/stimulus"
import { computePosition, offset, shift, flip } from "@floating-ui/dom"

export default class extends Controller {
  static values = { content: String }
  static bindings = [
    ["mouseenter", "showTooltip"],
    ["mouseleave", "hideTooltip"],
    ["focus", "showTooltip"],
    ["blur", "hideTooltip"],
  ]

  connect() {
    const tooltip = this.createTooltip()
    document.body.appendChild(tooltip)
    this.constructor.bindings.forEach(([event, fn]) => {
      this.element.addEventListener(event, () => this[fn](this.element, tooltip))
    })
  }

  showTooltip(el, tt) {
    computePosition(el, tt, {
      middleware: [
        offset(20),
        shift(),
        flip(),
      ],
    }).then(({x, y}) => {
      tt.classList.remove("hidden")
      tt.classList.add("block")
      Object.assign(tt.style, {left: `${x}px`, top: `${y}px`})
    })
  }

  hideTooltip(el, tt) {
    tt.classList.remove("block")
    tt.classList.add("hidden")
  }

  createTooltip() {
    const tooltip = document.createElement("div")

    tooltip.innerText = this.contentValue.trim()
    tooltip.classList.add(
      "absolute",
      "hidden",
      "p-5",
      "bg-azure-50",
      "rounded",
      "border",
      "border-azure-100",
      "shadow",
      "text-azure-900",
      "dark:shadow-gray-900",
      "dark:bg-gray-900",
      "dark:border-gray-700",
      "dark:text-gray-on-gray-900"
    )

    return tooltip
  }
}
