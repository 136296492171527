import { Controller } from "@hotwired/stimulus"
import { setClass } from "../lib/helpers"

// Renders flash message bar that persists across page visits.
//
// Server:
//
// Set flash[:notice] and flash[:alert] on the server to initiate flash messages
// from the server on page load. Ensure that data-turbo-permanent has been removed prior
// doing so by first sending the flash:impermanent message to the flash bar using the
// exported impermanent() function.
//
// Client:
//
// Use the flash(type, message) function to initiate flash messages from the client.
//
export default class extends Controller {
  static values = { type: String, message: String, progress: Number, initialized: Boolean }
  static targets = [ "message", "progress" ]
  static SLIDE_DURATION = 333 // match the css animation
  static FRAME_TIME = 16 // millisecond per frame
  static containerClasses = [
    "absolute",
    "top-0",
    "right-0",
    "left-0",
    "h-12",
    "z-10",
    "-translate-y-12",
    "transition-[transform]",
    `duration-[${this.SLIDE_DURATION}ms]`,
    "ease-in-out",
    "flex",
    "flex-col",
    "text-center",
  ]
  static containerNoticeClasses = [
    "border-t",
    "border-t-azure-700",
    "dark:border-t-azure-900",
    "bg-azure-700",
    "dark:bg-azure-900",
  ]
  static containerAlertClasses = [
    "border-t",
    "border-t-red-700",
    "dark:border-t-azure-900",
    "bg-red-700",
    "dark:bg-red-900",
  ]
  static visibleClasses = [
    "translate-y-0",
  ]
  static messageClasses = [
    "flex",
    "flex-1",
    "justify-center",
    "items-center",
    "text-white",
    "transition-[background-color]",
    `duration-[${this.SLIDE_DURATION}ms]`,
  ]
  static messageNoticeClasses = [
    "bg-azure-600",
    "dark:bg-azure-700",
    ]
  static messageAlertClasses = [
    "bg-red-600",
    "dark:bg-red-900",
  ]
  static progressClasses = [
    "h-0.5",
  ]
  static progressNoticeClasses = [
    "bg-azure-50",
    "dark:bg-azure-400",
  ]
  static progressAlertClasses = [
    "bg-red-50",
    "dark:bg-red-400",
  ]

  initialize() {
    this.incrementProgress = this.incrementProgress.bind(this)
    this.adjustPosition = this.adjustPosition.bind(this)
  }

  connect() {
    this.element.addEventListener("flash:message", this.flashMessageEvent)
    this.element.addEventListener("flash:impermanent", this.flashImpermanentEvent)
    document.addEventListener("scroll", this.adjustPosition)

    // Prevent multiple calls to start() by tracking initializedValue
    if (!this.initializedValue) {
      this.initializedValue = true

      setClass(this.element, this.constructor.containerClasses)
      setClass(this.messageTarget, this.constructor.messageClasses)
      setClass(this.progressTarget, this.constructor.progressClasses)

      if (this.messageValue)
        setTimeout(() => this.start(), 333)
    }
  }

  disconnect() {
    document.removeEventListener("scroll", this.adjustPosition)
  }

  flashMessageEvent = (event) => {
    this.typeValue = event.detail.flash.type
    this.messageValue = event.detail.flash.message
    this.start()
  }

  flashImpermanentEvent = (_) => {
    this.element.removeAttribute("data-turbo-permanent")
  }

  start() {
    setTimeout(() => {
      this.render()
      this.show()
      this.trackProgress()
      this.adjustPosition()
    }, this.constructor.FRAME_TIME)
  }

  stop() {
    setTimeout(() => this.hide(), this.constructor.FRAME_TIME)
    setTimeout(() => this.reset(), this.constructor.SLIDE_DURATION)
  }

  render() {
    switch (this.typeValue) {
    case "notice":
      setClass(
        this.element,
        this.constructor.containerClasses,
        this.constructor.containerNoticeClasses
      )
      setClass(
        this.messageTarget,
        this.constructor.messageClasses,
        this.constructor.messageNoticeClasses
      )
      setClass(
        this.progressTarget,
        this.constructor.progressClasses,
        this.constructor.progressNoticeClasses
      )
      break
    case "alert":
      setClass(
        this.element,
        this.constructor.containerClasses,
        this.constructor.containerAlertClasses
      )
      setClass(
        this.messageTarget,
        this.constructor.messageClasses,
        this.constructor.messageAlertClasses
      )
      setClass(
        this.progressTarget,
        this.constructor.progressClasses,
        this.constructor.progressAlertClasses
      )
      break
    }

    this.messageTarget.innerHTML = this.messageValue
  }

  reset() {
    this.messageValue = ""
    this.typeValue = ""
    this.progressValue = 0
  }

  show() {
    this.element.classList.add(...this.constructor.visibleClasses)
  }

  hide () {
    this.element.classList.remove(...this.constructor.visibleClasses)
  }

  trackProgress() {
    if (!this.typeValue || !this.messageValue)
      return

    if (this.progressValue) {
      // reset progress when active (avoid concurrent calls to incrementProgress)
      this.progressValue = 0
    } else {
      // kick off incrementer when idle
      this.incrementProgress()
    }
  }

  incrementProgress() {
    if (this.progressValue >= 100) {
      this.progressTarget.style.width = `100%`
      this.stop()
    } else {
      this.progressTarget.style.width = `${this.progressValue}%`
      this.progressValue += 0.425
      setTimeout(this.incrementProgress, this.constructor.FRAME_TIME)
    }
  }

  adjustPosition(e) {
    if (window.scrollY >= 80) {
      this.element.classList.add("fixed")
      this.element.classList.remove("absolute")
    } else {
      this.element.classList.add("absolute")
      this.element.classList.remove("fixed")
    }
  }
}

export function flash(type, ...message) {
  const event = new CustomEvent(
    "flash:message",
    {
      detail: {
        flash: {
          type: type,
          message: message.join(" ")
        }
      }
    }
  )

  dispatch(event)
}

export function impermanent() {
  const event = new CustomEvent("flash:impermanent")

  dispatch(event)
}

function dispatch(event) {
  document.getElementById("flash-component")?.dispatchEvent(event)
}
