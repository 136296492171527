import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {src: String, interval: Number, reloadTimestamp: Number}

  connect() {
    this.interval = setInterval(this.checkup.bind(this), 1000)
  }

  disconnect() {
    clearInterval(this.interval)
  }

  checkup() {
    if (this.reloadTimestampValue + this.intervalValue <= this.timestamp()) this.reload()
  }

  reload() {
    this.element.src = null
    this.element.src = this.srcValue
    this.reloadTimestampValue = this.timestamp()
  }

  timestamp() {
    return Math.round(new Date().getTime() / 1000)
  }
}
