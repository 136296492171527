import { Controller } from "@hotwired/stimulus"

// Creates a hidden field with the name form[url] containing the current url.
//
export default class extends Controller {
  connect() {
    const input = document.createElement("input")
    input.type = "hidden"
    input.name = "form[url]"
    input.value = window.location.href
    this.element.prepend(input)
  }
}
